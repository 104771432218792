import React, { useState } from 'react'
import { DateRangePicker } from 'materialui-daterange-picker'
import moment from 'moment'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import { MyKeyword } from '../_MyComponents'

const SearchByUpload = ({
  users,
  selectedUsers,
  onUserSelected,
  onDateSelected,
  startDate,
  endDate,
  dateRangeType,
}) => {
  const [open, setOpen] = useState(false)
  const toggle = () => setOpen(!open)

  const getRangeDisplay = (startDate, endDate) => {
    return `${moment(startDate).format('MM-DD-YYYY')} - ${moment(
      endDate
    ).format('MM-DD-YYYY')}`
  }

  const resetDate = () => {
    onDateSelected({
      dateRangeType: null,
      endDate: null,
      startDate: null,
    })
  }

  const changeDate = (dateType) => {
    if (dateRangeType === dateType) {
      resetDate()
    } else {
      onDateSelected({
        dateRangeType: dateType,
      })
    }
  }

  const SearchByDate = () => {
    return (
      <div className="filter-section">
        <h2 className="h2">Upload date</h2>
        {/* <div className={"title-2"}>DATE</div> */}
        <div className="filter-tags">
          <MyKeyword
            onClick={() => {
              changeDate('week')
            }}
            selected={dateRangeType === 'week'}
            text="Past week"
          />

          <MyKeyword
            onClick={() => {
              changeDate('month')
            }}
            selected={dateRangeType === 'month'}
            text="Past month"
          />
          <MyKeyword
            onClick={() => {
              changeDate('year')
            }}
            selected={dateRangeType === 'year'}
            text="Past year"
          />
          {/* popup UI issue, resolve later and uncomment */}
          <MyKeyword
            onClick={() => {
              toggle()
            }}
            selected={dateRangeType === 'custom'}
            text={
              startDate && dateRangeType === 'custom'
                ? getRangeDisplay(startDate, endDate)
                : 'Pick date'
            }
          />
          {dateRangeType === 'custom' && <RotateLeftIcon onClick={resetDate} />}
        </div>
        <div className="date-picker">
          <DateRangePicker
            maxDate={new Date()}
            open={open}
            toggle={toggle}
            onChange={(range) => {
              onDateSelected({
                ...range,
                dateRangeType: 'custom',
              })
            }}
          />
        </div>
      </div>
    )
  }

  const SearchByUsers = () => {
    return (
      <div className="filter-section">
        {!!users?.length && <h2 className="h2">Uploaded by</h2>}
        {/* {!!users?.length && <div className={"title-2"}>PEOPLE</div>} */}
        <div className="filter-tags">
          {users
            .sort((a, b) => (a.count > b.count ? -1 : 1))
            .slice(0, 5)
            .map((user, idx) => {
              const { username, name } = user
              const selected = selectedUsers.find(
                (userId) => userId.username === username
              )
              const displayName = name ? name : username
              return (
                <MyKeyword
                  onClick={() => {
                    onUserSelected(
                      selected
                        ? (selectedUsers = selectedUsers.filter((userId) => {
                            return userId.username !== username
                          }))
                        : selectedUsers.concat(user)
                    )
                  }}
                  selected={selected}
                  text={displayName}
                  key={user._id}
                />
              )
            })}
        </div>
      </div>
    )
  }

  return (
    <div className="filter-content-wrapper">
      <SearchByDate />
      <SearchByUsers />
    </div>
  )
}

export default SearchByUpload
