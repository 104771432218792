import { inject, observer } from 'mobx-react'
import Preview from './Preview'
import './style.scss'
import './mobile.scss'

export default inject(
  ({ filesStore, orgsWorkspacesStore, authenticationStore }) => ({
    organization: orgsWorkspacesStore.organization,
    orgName: orgsWorkspacesStore.orgName,
    workspace: orgsWorkspacesStore.workspace,
    workspaceName: orgsWorkspacesStore.workspaceName,
    workspaceId: orgsWorkspacesStore.workspaceId,
    username: authenticationStore.username,
    file: filesStore.currentFile,
    getUsersFromWorkspace: orgsWorkspacesStore.getUsersFromWorkspace,
    getAllTags: filesStore.getAllTags,
    updateFileName: filesStore.updateFileName,
    isAllowed: orgsWorkspacesStore.isAllowed,
    reset: filesStore.resetCurrentFile,
    previewNextPrev: filesStore.previewNextPrev,
    isFileFirst: filesStore.isFileFirst,
    isFileLast: filesStore.isFileLast,
    updateFileType: filesStore.updateFileType,
    setCDN: filesStore.setCDN,
    isSingleFileUser: orgsWorkspacesStore.isSingleFileUser,
    downloadFile: filesStore.downloadFile,
    transcription: filesStore.transcription,
    simlarFiles: filesStore.simlarFiles,
    logo: orgsWorkspacesStore.logo,
    userState: authenticationStore.userState,
    updateUserState: authenticationStore.updateUserState,
    deleteFile: filesStore.deleteFile,
    proxyDownloadFile: filesStore.proxyDownloadFile,
    orgPlanLimits: orgsWorkspacesStore.orgPlanLimits,
  })
)(observer(Preview))
