import React, { useState, useMemo, useRef, useEffect } from 'react'
import Comments from '../Comments'
import { RULES } from '../../store/OrgsWorkspacesStore/permissions'
import ActionDialog from '../ActionDialog'
import './detailsBar.scss'
import { MyKeyword, MyTabs, MyTab, MyButton } from '../_MyComponents'

import ExportTab from './Tabs/ExportTab'
import DetailsTab from './Tabs/DetailsTab'
import TranscriptTab from './Tabs/TranscriptTab'

const DetailsBar = ({
  // from store
  orgId,
  workspaceId,
  username,
  role,
  addToFileViewed,
  allTags,
  deleteFile,
  isAllowed,
  addOrgTag,
  tagsList,
  onAddTag,
  onRemoveTag,
  addCollectionToFile,
  removeCollectionToFile,
  updateFileDescription,
  restoreVersion,
  setTranscription,
  transcription,
  userState,
  updateUserState,

  // from parent
  file,
  fileId,
  tags,
  detailBarOpen,
  openDetailBar,
  setVersionIndex,
  closePreview,
  setView,
  setExportMode,
  createCroppedImage,
  downloadCroppedImage,
  aspectRatio,
  setAspectRatio,
  exportFormat,
  setExportFormat,
  exportQuality,
  setExportQuality,
  crop,
  setCrop,
  resize,
  setResize,
  ratios,
  cropperRef,
  tabValue,
  setTabValue,
  isExportableImage,
}) => {
  const [entPopup, setEntPopup] = useState(false)
  const [tabIndex, setTabIndex] = useState([
    { label: 'Details', index: 0 },
    { label: 'Export' },
    { label: 'Comments' },
    { label: 'Transcript' },
  ])

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const handleTabChange = (event, newValue) => {
    addToFileViewed({ fileId }) // why is this here?
    setTabValue(newValue)
  }

  // code required to make tabs dynamic

  const exportExists = isExportableImage() && isAllowed(RULES.DOWNLOAD_DOC)
  const commentsExists = isAllowed(RULES.COMMENT_ON_A_FILE)
  const transcriptExists =
    !!file?.transcriptionStatus && file?.transcriptionStatus !== 'irrelevant'

  useMemo(() => {
    const initTabIndex = [
      { label: 'Details', index: 0 },
      { label: 'Export', index: exportExists ? 1 : -1 },
      { label: 'Comments', index: commentsExists ? 1 + exportExists : -1 },
      {
        label: 'Transcript',
        index: transcriptExists ? 1 + exportExists + commentsExists : -1,
      },
    ]

    setTabIndex(initTabIndex)

    //if current tabValue isn't a index that exists, set it to 0
    if (
      tabValue !== 0 &&
      initTabIndex.findIndex((tab) => tab.index === tabValue) === -1
    ) {
      console.log('setting tab to 0')
      setTabValue(0)
    }
  }, [exportExists, commentsExists, transcriptExists])

  return (
    <>
      <div
        className={`detail-bar detail-bar-mobile ${
          detailBarOpen ? 'detail-open' : ''
        }`}
      >
        <div>
          <MyTabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            indicatorColor="primary"
          >
            <MyTab label="Details" {...a11yProps(0)} />
            {isExportableImage() && // we'll resolve this issue later
              isAllowed(RULES.DOWNLOAD_DOC) && (
                <MyTab label="Export" {...a11yProps(tabIndex[1].index)} />
              )}
            {isAllowed(RULES.COMMENT_ON_A_FILE) && (
              <MyTab label="Comments" {...a11yProps(tabIndex[2].index)} />
            )}
            {!!file?.transcriptionStatus &&
              file?.transcriptionStatus !== 'irrelevant' && (
                <MyTab label="Transcript" {...a11yProps(tabIndex[3].index)} />
              )}
          </MyTabs>
        </div>

        <div className="details-content">
          {/* details */}
          <TabPanel value={tabValue} index={0}>
            <DetailsTab
              orgId={orgId}
              workspaceId={workspaceId}
              username={username}
              role={role}
              allTags={allTags}
              deleteFile={deleteFile}
              isAllowed={isAllowed}
              addOrgTag={addOrgTag}
              tagsList={tagsList}
              onAddTag={onAddTag}
              onRemoveTag={onRemoveTag}
              addCollectionToFile={addCollectionToFile}
              removeCollectionToFile={removeCollectionToFile}
              updateFileDescription={updateFileDescription}
              restoreVersion={restoreVersion}
              file={file}
              fileId={fileId}
              tags={tags}
              setVersionIndex={setVersionIndex}
              closePreview={closePreview}
              setView={setView}
            />
          </TabPanel>

          {/* export */}
          {isAllowed(RULES.DOWNLOAD_DOC) && (
            <TabPanel value={tabValue} index={tabIndex[1].index}>
              <ExportTab
                file={file}
                createCroppedImage={createCroppedImage}
                downloadCroppedImage={downloadCroppedImage}
                aspectRatio={aspectRatio}
                setAspectRatio={setAspectRatio}
                exportFormat={exportFormat}
                setExportFormat={setExportFormat}
                exportQuality={exportQuality}
                setExportQuality={setExportQuality}
                crop={crop}
                setCrop={setCrop}
                resize={resize}
                setResize={setResize}
                ratios={ratios}
                cropperRef={cropperRef}
              />
            </TabPanel>
          )}

          {/* comments */}
          {isAllowed(RULES.COMMENT_ON_A_FILE) && (
            <TabPanel value={tabValue} index={tabIndex[2].index}>
              <div className="section">
                <Comments fileId={fileId} />
              </div>
            </TabPanel>
          )}

          {file?.transcriptionStatus !== 'irrelevant' && (
            <TabPanel
              value={tabValue}
              index={tabIndex[3].index} //if comments are not allowed, the index of the tab is 1
            >
              <div className="section">
                <TranscriptTab
                  setTranscription={setTranscription}
                  transcription={transcription}
                  userState={userState}
                  updateUserState={updateUserState}
                  file={file}
                  fileId={fileId}
                />
              </div>
            </TabPanel>
          )}
        </div>
      </div>

      {detailBarOpen && (
        <div className="black-bg-detail-mobile" onClick={openDetailBar}></div>
      )}
      <ActionDialog
        actionName={'Okay'}
        action={() => setEntPopup(false)}
        question={`Need video translation?`}
        comment={
          "Automatic video tranlation is a premium feature and isn't part of your current plan. Contact us at hello@tagbox.io to learn more and add it to your plan."
        }
        //color={'warning'}
        isOpen={entPopup}
      />
    </>
  )
}

export default DetailsBar

const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      className="details-tabs"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div sx={{ p: 3 }} className="details-tab">
          {children}
        </div>
      )}
    </div>
  )
}
