import React, { useState, useEffect, useStyle } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { MyButton } from '../_MyComponents'
import Switch from '@material-ui/core/Switch'
import Snackbar from '@material-ui/core/Snackbar'
import Divider from '@mui/material/Divider'

const EmbedFile = ({
  open,
  setOpen,
  fileCdnURL,
  fileId,
  setCDN,
  cdnCount,
  cdnCountLimit,
  setIsPlanPrompt,
}) => {
  const [urlSnackbarOpen, setUrlSnackbarOpen] = useState(false)
  const [shareToggle, setShareToggle] = useState(null)
  const [cdnURL, setCdnURL] = useState(fileCdnURL || '')
  const [count, setCount] = useState(cdnCount)
  const [isPlanPromptState, setIsPlanPromptState] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleToggleShare = async (e) => {
    if (isPlanPromptState && !shareToggle) {
      setIsPlanPrompt(true)
    } else {
      setShareToggle((shareToggle) => !shareToggle)
      setCount(shareToggle ? count - 1 : count + 1)
      var url = await setCDN({ fileId, enable: !shareToggle })
      setCdnURL(url)
    }
  }

  const copyUrl = () => {
    setUrlSnackbarOpen(true)
    navigator.clipboard.writeText(cdnURL)
  }

  useEffect(() => {
    setShareToggle(fileCdnURL && fileCdnURL.length > 0 ? true : false)
    if (fileCdnURL && fileCdnURL.length > 0) {
      setCdnURL(fileCdnURL)
    }
  }, [fileCdnURL])

  useEffect(() => {
    if (
      cdnCountLimit > 0 &&
      !(fileCdnURL && fileCdnURL.length > 0) &&
      cdnCount >= cdnCountLimit
    ) {
      setIsPlanPromptState(true)
    } else {
      setIsPlanPromptState(false)
    }
  }, [fileCdnURL, cdnCount])

  const closeUrlSnackbar = (e, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setUrlSnackbarOpen(false)
  }

  const texts = {
    title: 'Embed file (CDN link)',
    description:
      'Embed files directly on your site with a simple link. Always updated, fast, and secure.',
  }

  return (
    <Dialog
      onClose={handleClose}
      maxWidth={'xs'}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="form-dialog-title">{texts.title}</DialogTitle>
      <DialogContent>
        <div className="share-content-wrapper">
          <div className="content-item">
            <p className={'p'}>{texts.description}</p>
            <Switch
              className={'share-toggle'}
              checked={shareToggle}
              color="primary"
              onChange={handleToggleShare}
            />
            <div className={'copy-url ' + (!shareToggle ? 'disabled' : '')}>
              <MyButton
                className={'url'}
                disableRipple
                onClick={copyUrl}
                disabled={!shareToggle}
              >
                {cdnURL}
              </MyButton>
              <MyButton
                onClick={copyUrl}
                disabled={!shareToggle}
                variant="contained"
                color="secondary"
              >
                Copy
              </MyButton>
              <Snackbar
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                open={urlSnackbarOpen}
                autoHideDuration={4000}
                onClose={closeUrlSnackbar}
                message="Link copied!"
              />
            </div>
          </div>
          {cdnCountLimit > 0 && (
            <div className="content-item">
              <Divider orientation="ohrizontal" variant="middle" flexItem />
              <div className="cdn-wrapper">
                <div className="embed-counter">
                  <p className="p">Embed links used</p>
                  <p className="p">
                    <strong>{count}</strong> / {cdnCountLimit}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </DialogContent>

      <DialogActions>
        <MyButton onClick={handleClose} color="primary" variant="contained">
          Done
        </MyButton>
      </DialogActions>
    </Dialog>
  )
}

export default EmbedFile
