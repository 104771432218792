import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import './style.scss'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Tooltip from '@material-ui/core/Tooltip'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import SHARE from '../../assets/share-icon.svg'
import EMBED from '../../assets/embed-icon.svg'
import DownloadIcon from '../../assets/download-icon.svg'
import DELETE_LINK from '../../assets/delete-icon.svg'
import UPLOAD from '../../assets/upload-icon.svg'
import { MyButton } from '../_MyComponents'
import NewVersionUpload from '../NewVersionUpload'
import ActionDialog from '../ActionDialog'

import ShareFile from './ShareFile'
import EmbedFile from './EmbedFile'
import { set } from 'mobx'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    zIndex: 99,
    width: '100%',
    top: 0,
    borderBottom: '1px solid rgba(0, 0, 0, 0.04)',
  },
  toolBar: {
    background: 'white',
    minHeight: '72px',
  },
}))

const TopBarPreview = ({
  organization,
  file,
  handleClose,
  allowedToEdit,
  allowedToShare,
  handleChange,
  downloadFile,
  openDetailBar,
  allowedToUpgradeVersion,
  updateFileType,
  setCDN,
  isSingleFileUser,
  logo,
  isAllowed,
  RULES,
  deleteFile,
  orgPlanLimits,
  setIsPlanPrompt,
}) => {
  const classes = useStyles()

  const [newVersionOpen, setNewVersionOpen] = React.useState(false)
  const [shareDialogOpen, setShareDialogOpen] = useState(false)
  const [cdnDialogOpen, setCdnDialogOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

  const fileDelete = async (_id) => {
    setDeleteDialogOpen(false)
    await deleteFile(_id)
    handleClose()
  }

  return (
    <div className={classes.root + ' top-bar-preview'}>
      <ShareFile
        open={shareDialogOpen}
        setOpen={setShareDialogOpen}
        fileType={file.type}
        fileId={file._id}
        updateFileType={updateFileType}
      ></ShareFile>
      <EmbedFile
        open={cdnDialogOpen}
        setOpen={setCdnDialogOpen}
        fileCdnURL={file.cdnURL}
        fileId={file._id}
        setCDN={setCDN}
        cdnCount={orgPlanLimits.cdnCount}
        cdnCountLimit={orgPlanLimits.cdnCountLimit}
        isAllowed={isAllowed}
        setIsPlanPrompt={setIsPlanPrompt}
      ></EmbedFile>
      <AppBar position="static">
        <Toolbar className={classes.toolBar + ' header-menu'}>
          <div className="topbar-left">
            {!window.mobileCheck() && (
              <span
                className="flex-row"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (!isSingleFileUser()) {
                    handleClose()
                  }
                }}
              >
                {!!organization && logo && (
                  <img className="logo" src={logo} alt="Logo" />
                )}
              </span>
            )}
            <div className={'preview-tab-title-name'}>
              <TextField
                defaultValue={file?.name}
                onChange={(e) => handleChange(e)}
                fullWidth
                disabled={!allowedToEdit}
              />
              {/* {file.ext === "www" && (
                    <a
                      style={{ cursor: "pointer", color: "white" }}
                      href={file.preview}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ({file.preview})
                    </a>
                  )} */}
            </div>
          </div>
          <div className={classes.filename + ' preview-tab-title'}>
            <div className={'preview-tab-title-icons'}>
              {allowedToShare && (
                <Tooltip title={'Embed file'}>
                  <IconButton
                    className="filter-icon"
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => setCdnDialogOpen(true)}
                  >
                    <img src={EMBED} alt="Embed file" />
                  </IconButton>
                </Tooltip>
              )}
              {isAllowed(RULES.DELETE_DOC) && (
                <Tooltip title={'Delete file'}>
                  <IconButton
                    className="filter-icon"
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => setDeleteDialogOpen(true)}
                  >
                    <img src={DELETE_LINK} alt="Delete icon" />
                  </IconButton>
                </Tooltip>
              )}
              {allowedToShare && (
                <Tooltip title={'Share file'}>
                  <IconButton
                    className="filter-icon"
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => setShareDialogOpen(true)}
                  >
                    <img src={SHARE} alt="share icon" />
                  </IconButton>
                </Tooltip>
              )}
              {file?.storageType === 's3' && allowedToUpgradeVersion && (
                <Tooltip title={'Upload a new version'}>
                  <IconButton
                    className="filter-icon"
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => setNewVersionOpen(true)}
                  >
                    <img src={UPLOAD} alt="share icon" />
                  </IconButton>
                </Tooltip>
              )}
              {file?.ext !== 'www' &&
                isAllowed(RULES.DOWNLOAD_DOC) &&
                (window.mobileCheck() ? (
                  <IconButton
                    className="filter-icon"
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => downloadFile(file)}
                  >
                    <img src={DownloadIcon} />
                  </IconButton>
                ) : (
                  <MyButton
                    onClick={() => downloadFile(file)}
                    variant="contained"
                    color="primary"
                    startIcon={<img src={DownloadIcon} />}
                  >
                    Download
                  </MyButton>
                ))}
              {window.mobileCheck() && isAllowed(RULES.VIEW_DETAILS_BAR) && (
                <MoreVertIcon
                  className="preview-menu-icon preview-icon"
                  aria-controls="top-bar-menu"
                  aria-haspopup="true"
                  onClick={openDetailBar}
                />
              )}
            </div>
          </div>
          {!isSingleFileUser() && (
            <IconButton
              className="close-wizard"
              onClick={handleClose}
              component="span"
            >
              <CloseIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      {newVersionOpen && (
        <NewVersionUpload
          fileId={file._id}
          close={() => setNewVersionOpen(false)}
          isOpen={newVersionOpen}
        />
      )}
      {deleteDialogOpen === true && (
        <ActionDialog
          actionName={'Delete'}
          action={() => {
            fileDelete(file._id)
          }}
          cancel={() => setDeleteDialogOpen(false)}
          question={`Delete file ${file.name}?`}
          comment={
            "You're about to permanently delete this file, its comments and related content, and all of its data."
          }
          color={'warning'}
          isOpen={deleteDialogOpen}
        />
      )}
    </div>
  )
}

export default TopBarPreview
