import React, { useState } from 'react'
import './BulkActionBar.scss'
import Snackbar from '@material-ui/core/Snackbar'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import DELETE from '../../assets/delete-icon.svg'
import DOWNLOAD from '../../assets/download-icon.svg'
import COLLECTIONS from '../../assets/WhiteCollections.svg'
import TAG from '../../assets/tag-icon.svg'

import { makeStyles } from '@material-ui/core/styles'
import { RULES } from '../../store/OrgsWorkspacesStore/permissions'

const useStyles = makeStyles(() => ({
  actionButton: {
    textTransform: 'none',
    color: 'white',
    fontSize: '0.875rem',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  icon: {
    marginRight: '10px',
  },
}))

const BulkActionBar = ({
  // from store
  isAllowed,
  bulkDownload,

  // from parent
  open,
  quantity,
  selectedList,
  close,
  setShowDialog,
  setShowBulkEditDialog,
  setType,
  downloadFile,
}) => {
  const classes = useStyles()

  const onBulkDownload = async () => {
    if (selectedList.length > 1) {
      const fileIds = selectedList.map((file) => {
        return file.fileId
      })
      bulkDownload({ fileIds })
    } else {
      downloadFile(selectedList[0])
    }
  }

  return (
    <>
      <Snackbar
        className={`snackbar`}
        //TransitionComponent="Grow"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        /*onClose={()=>alert('close')}*/
        message={quantity === 1 ? `${quantity} file` : `${quantity} files`}
        action={
          <div className={!!window.mobileCheck() ? 'mobile-action-bar' : ''}>
            {
              <Button
                size="small"
                className={classes.actionButton}
                onClick={onBulkDownload}
              >
                <img src={DOWNLOAD} className="icon" />
                Download
              </Button>
            }
            {isAllowed(RULES.ADD_TAG) && (
              <Button
                size="small"
                className={classes.actionButton}
                onClick={() => {
                  setShowBulkEditDialog(true)
                  setType('REGULAR')
                }}
              >
                <img src={TAG} className="icon" /> Edit tags
              </Button>
            )}
            {isAllowed(RULES.ADD_TO_COLLECTION) && (
              <Button
                size="small"
                className={classes.actionButton}
                onClick={() => {
                  setShowBulkEditDialog(true)
                  setType('COLLECTION')
                }}
              >
                <img src={COLLECTIONS} className="icon" /> Edit collections
              </Button>
            )}
            {isAllowed(RULES.DELETE_DOC) && (
              <Button
                size="small"
                className={classes.actionButton}
                onClick={() => setShowDialog(true)}
              >
                <img src={DELETE} className="icon" /> Delete
              </Button>
            )}
            <Button
              size="small"
              className={classes.actionButton}
              onClick={close}
            >
              <CloseIcon className={classes.icon} />
            </Button>
          </div>
        }
        key="snackbar"
      />
    </>
  )
}

export default BulkActionBar
