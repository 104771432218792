import React, { useState, useRef, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import { RULES } from '../../store/OrgsWorkspacesStore/permissions'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import ActionDialog from '../ActionDialog'

const Person = ({
  person,
  renamePerson,
  removePerson,
  onPersonSelected,
  setView,
  isAllowed,
  isClickable = true,
  getRecentlyUploadedFiles,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [showDialogDelete, setShowDialogDelete] = useState(false)

  const { name, defaultFaceLocation, _id } = person

  const handleClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setAnchorEl(e.currentTarget)
  }
  const handleClose = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setAnchorEl(null)
  }

  const handleRemovePopUp = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setShowDialogDelete(true)
    //removePerson({ personId: _id})
    handleClose(e)
  }

  const handleRemovePerson = async () => {
    setShowDialogDelete(false)
    await removePerson({ personId: _id })
    getRecentlyUploadedFiles()
  }

  const menu = (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(e) => handleClick(e)}
        size="small"
      >
        <MoreVertIcon className="more-icon" />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={(e) => handleClose(e)}
      >
        <MenuItem onClick={handleRemovePopUp}>
          <DeleteIcon className="menu-item-icon" />
          Delete person
        </MenuItem>
      </Menu>
    </>
  )

  /*
  const delayedHandleChange = debounce(
    (val) => {
      const newName = val;
      renamePerson({ personId: _id, name: newName });
    },
    1500,
  );

  const handleChange = (e) => {
    delayedHandleChange(e.target.value);
  };
  */

  const handleBlur = async (e) => {
    const newName = e.target.value
    if ((!name || name.length === 0) && (!newName || newName.length == 0))
      return
    if (newName === name) return
    await renamePerson({ personId: _id, name: newName })
    getRecentlyUploadedFiles()
  }

  const onClickEnter = async (e) => {
    if (
      e.key === 'Enter' &&
      e.target.value &&
      e.target.value.length > 1 &&
      !e.repeat
    ) {
      const newName = e.target.value
      if ((!name || name.length === 0) && (!newName || newName.length == 0))
        return
      if (newName === name) return
      await renamePerson({ personId: _id, name: newName })
      getRecentlyUploadedFiles()
    }
  }

  const onPersonClicked = () => {
    setView('home')
    onPersonSelected(person, true)
  }

  return (
    <div
      className={`person-container ${isClickable && 'clickable'}`}
      onClick={isClickable ? onPersonClicked : () => {}}
    >
      <img src={defaultFaceLocation} alt={name} />
      {isAllowed(RULES.DELETE_PERSONS) && (
        <div className="person-top-actions">{menu}</div>
      )}
      <div className="person-actions" onClick={(e) => e.stopPropagation()}>
        {
          <TextField
            defaultValue={name}
            placeholder="Who is this?"
            fullWidth
            className="h2"
            InputProps={{ className: 'person-input' }}
            //onChange={(e) => handleChange(e)}
            onClick={(e) => e.stopPropagation()}
            onKeyDown={onClickEnter}
            onBlur={(e) => handleBlur(e)}
            disabled={!isAllowed(RULES.EDIT_PERSONS)}
          />
        }
      </div>
      <div className={'hover-background'}></div>
      <ActionDialog
        actionName={'Delete'}
        action={() => handleRemovePerson()}
        cancel={() => setShowDialogDelete(false)}
        question={`Delete Person?`}
        comment={
          'Are you sure? This will delete this person from the filters permanently (but will not delete any images containing them).'
        }
        color={'warning'}
        isOpen={showDialogDelete}
      />
    </div>
  )
}

export default Person
