import React, { useState, useEffect, useRef } from 'react'
import { RULES } from '../../store/OrgsWorkspacesStore/permissions'
import Popover from '@material-ui/core/Popover'
import { TAG_TYPE } from '../../util/consts'
import './tagList.scss'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import { find } from 'lodash'
import TAG from '../../assets/tag.svg'
import { MyKeyword } from '../_MyComponents'
import TagsByCategories from './TagsByCategories'

const TagList = ({
  // direct
  isAllowed,
  addOrgTag,
  allTags,
  tagsList,
  allCollections,
  createCollection,
  categories,
  onPreSearchChanged,
  textToPreSearch,
  preSearchTagResults,
  resetTagPresearch,
  isJustCollectionUser,

  // from parent
  type = 'REGULAR',
  onAddTag,
  onRemoveTag,
  collections,
  onAddCollection,
  onRemoveCollection,
  id,
  anchor,
  origin,
  transform,
  onClose,
  tags,
  fileId,
}) => {
  const [value, setValue] = useState('')
  const [list, Setlist] = useState([])
  const [data, setData] = useState({})
  const [isResults, setIsResults] = useState(false)
  const [visibleCollections, setVisibleCollections] = useState([])
  const [catsWithMicellaneous, setCatsWithMicellaneous] = useState([])
  const [anchorEl, setAnchorEl] = useState(null) // categories secondary popover

  useEffect(() => {
    if (collections?.length) {
      var visible = collections.filter((collection) =>
        allCollections.find(
          (col) => col._id.toString() === collection._id.toString()
        )
      )
      setVisibleCollections(visible)
    } else {
      setVisibleCollections([])
    }
  }, [collections])

  const clearTextInput = () => {
    setValue('')
  }

  useEffect(() => {
    const notInCategories = list.filter(
      (tag) =>
        !categories.categoriesArrays.find((category) =>
          category.tags.find((t) => t._id === tag._id)
        )
    )
    const miscellaneousTags = {
      tags: notInCategories,
      _id: '11111',
      name: 'Miscellaneous',
    }
    notInCategories.length > 0 &&
      setCatsWithMicellaneous(
        categories.categoriesArrays.concat(miscellaneousTags)
      )
  }, [list])

  const anchorOrigin = origin || {
    vertical: 'bottom',
    horizontal: 'center',
  }

  const transformOrigin = transform || {
    vertical: 'top',
    horizontal: 'center',
  }

  useEffect(() => {
    if (Object.keys(preSearchTagResults).length) {
      if (type === 'REGULAR') {
        const newTags = preSearchTagResults.tags.filter(
          (tag) => !tags.find((t) => t._id === tag._id)
        )
        const tagsWithType = newTags.map((tag) => {
          return { ...tag, type: 'REGULAR' }
        })
        Setlist(tagsWithType)
      } else if (type === 'COLLECTION') {
        const colWithType = preSearchTagResults.collections.map((col) => {
          return { ...col, type: 'COLLECTION' }
        })
        Setlist(colWithType)
      } else if (type === 'AUTOMATION') {
        const newPersons = preSearchTagResults.persons.map((person) => {
          return { ...person, type: 'REGULAR', isPerson: true }
        })
        Setlist(preSearchTagResults.tags.concat(newPersons))
      }
    } else {
      if (type === 'REGULAR' || type === 'AUTOMATION') {
        //filter out existing tags
        Setlist(allTags.filter((tag) => !tags.find((t) => t._id === tag._id)))
      } else if (type === 'COLLECTION') {
        Setlist(allCollections)
      }
    }
  }, [preSearchTagResults])

  const getTagToAdd = ({ value, allTags, allCollections, type }) => {
    if (value.length < 2) {
      return null
    }
    return type === 'REGULAR' || type === 'AUTOMATION'
      ? !allTags.find((tag) => value === tag.name && tag.type === type) && value
      : !allCollections.find((collection) => value === collection.name) && value
  }

  const tagToAdd = getTagToAdd({ value, allTags, allCollections, type })

  const handleClose = () => {
    onClose()
    setValue('')
    resetTagPresearch()
  }

  const onClickEnter = (e) => {
    if (e.key === 'Enter' && e.target.value && e.target.value.length > 1) {
      if (type === 'REGULAR' || type === 'AUTOMATION') {
        if (!isAllowed(RULES.CREATE_TAG)) {
          return
        }
        const tag = find(
          tagsList,
          (tag) => tag.name.toLowerCase() === e.target.value.toLowerCase()
        )
        if (tag) {
          lowerCaseAddTag(fileId, tag)
        } else {
          addOrgTag({ tag: tagToAdd, type: TAG_TYPE.REGULAR }).then((nTag) => {
            lowerCaseAddTag(fileId, nTag)
          })
        }
      }
      if (type === 'COLLECTION') {
        const collection = find(
          allCollections,
          (collection) =>
            collection.name.toLowerCase() === e.target.value.toLowerCase()
        )
        if (collection) {
          onaddCollectionToFile({
            fileId,
            collectionId: collection._id,
            collection,
          })
        } else {
          createCollection({
            name: value,
            description: '',
            type: 'regular',
          }).then((newCollection) => {
            onaddCollectionToFile({
              fileId,
              collectionId: newCollection._id,
              collection: newCollection,
            })
          })
        }
      }
      clearTextInput()
    }
  }

  const lowerCaseAddTag = async (fileId, tag) => {
    await onAddTag({ fileId, tagsIds: [tag._id], tags: tags.concat(tag) })
    clearTextInput()
  }

  const onaddCollectionToFile = ({ fileId, collectionId, collection }) => {
    onAddCollection({
      fileId,
      collectionId,
      collections: collections.concat(collection),
    })
    clearTextInput()
  }

  const fileWidth = anchor?.clientWidth

  const findTagsInCategory = (_suggestedTags, tags) => {
    return tags.filter((tag) => {
      const tagFound = _suggestedTags.find((t) => t._id === tag._id)
      if (tagFound) {
        return tagFound
      }
    })
  }

  let suggestions = <></>

  if (
    type === 'REGULAR' &&
    !!categories?.categoriesArrays?.length &&
    !value.length
  ) {
    suggestions = (
      <TagsByCategories
        fileId={fileId}
        fileTags={tags.map((tag) => tag._id)}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        onAddTag={onAddTag}
        onRemoveTag={onRemoveTag}
        tags={tags}
      />
    )
  } else if (
    type === 'REGULAR' &&
    !!categories?.categoriesArrays?.length &&
    value.length
  ) {
    suggestions = (
      <div className="cat-suggestions-wrapper">
        {catsWithMicellaneous.map((category) => {
          const tagsInCategory = findTagsInCategory(list, category.tags)
          tagsInCategory.length > 0 && console.log({ tagsInCategory })
          return tagsInCategory.length > 0 ? (
            <div key={category._id} className="cat-suggestion-wrapper">
              <div className="cat-suggestion-name h3">{category.name}</div>
              <div className="cat-suggestion-tags">
                {tagsInCategory.map((tag) => (
                  <MyKeyword
                    key={tag._id}
                    onClick={() => {
                      lowerCaseAddTag(fileId, tag)
                    }}
                    text={tag.name}
                  />
                ))}
              </div>
            </div>
          ) : null
        })}
      </div>
    )
  } else {
    suggestions = list
      .slice()
      .sort((a, b) => (a.count > b.count ? 1 : -1))
      .sort((a, b) => (a.type < b.type ? 1 : -1))
      .filter((tag) => tag.name !== undefined)
      //.filter(tag => tag.type === type )
      .slice(0, 10)
      .map((tag) => {
        return (
          <MyKeyword
            key={`tag-to-add-${tag.name}`}
            avatar={tag?.defaultFaceLocation}
            onClick={() => {
              lowerCaseAddTag(fileId, tag)
            }}
            text={tag.name}
          />
        )
      })
  }

  const collectionSuggestions = list
    .slice()
    //.sort((a,b) => a.count > b.count ? 1: -1)
    //.sort((a,b) => a.type < b.type ? 1: -1)
    //.filter(tag => tag.type === type )
    //.filter(collection => collection?.permissions?.ADD_TO_COLLECTION === true)
    .slice(0, 10)
    .map((collection) => {
      // console.log({ collection, categories: toJS(categories), list });
      const isCollectionExist = visibleCollections?.find(
        (c) => c.name === collection.name
      )

      return (
        <MyKeyword
          key={`tag-to-add-${collection.name}`}
          onClick={() => {
            !isCollectionExist &&
              onaddCollectionToFile({
                fileId,
                collectionId: collection._id,
                collection,
              })
          }}
          text={collection.name}
        />
      )
    })

  ////////////////////////////
  //// presearch
  ////////////////////////////

  useEffect(() => {
    if (textToPreSearch) {
      setData(preSearchTagResults)
      setIsResults(
        preSearchTagResults?.tags
          ?.length /* || preSearchTagResults?.persons?.length */
      )
    } else {
      setData({})
      setIsResults(false)
    }
  }, [
    textToPreSearch,
    preSearchTagResults,
    preSearchTagResults?.tags,
    preSearchTagResults?.persons,
    preSearchTagResults?.collections,
  ])

  return (
    <Popover
      id={id}
      disableAutoFocus
      open={!!anchor}
      anchorEl={anchor}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      <div
        onMouseLeave={() => {
          setAnchorEl(null)
        }}
        className="popup-tags tagListWrapper"
        style={{ width: `${fileWidth}px` }}
      >
        <h3 className="h3">
          {type === 'REGULAR' || type === 'AUTOMATION'
            ? 'Assigned tags'
            : 'Assigned collections'}
        </h3>
        <div className="all-tags-list">
          {(type === 'REGULAR' || type === 'AUTOMATION') &&
            tags
              .slice()
              .sort((a, b) => (a.type > b.type ? 1 : -1))
              .filter((tag) => tag.type === type)
              .map((tag, index) => (
                <MyKeyword
                  key={index}
                  text={tag.name}
                  avatar={tag?.defaultFaceLocation}
                  onDelete={
                    isAllowed(RULES.REMOVE_TAGS)
                      ? () =>
                          onRemoveTag({
                            fileId,
                            tagsIds: [tag._id],
                            tags: [tag],
                          })
                      : undefined
                  }
                />
              ))}
          {type === 'COLLECTION' &&
            visibleCollections.slice().map((collection, index) => (
              <MyKeyword
                key={index}
                text={collection.name}
                onDelete={
                  isAllowed(RULES.REMOVE_FROM_COLLECTION)
                    ? () =>
                        onRemoveCollection({
                          fileId,
                          collectionId: collection._id,
                          collection,
                        })
                    : undefined
                }
              />
            ))}
        </div>
        <div className="tag-suggestion-wrapper">
          {isAllowed(RULES.ADD_TAG) && (
            <div className="search-input-wrapper">
              <OutlinedInput
                id="popover-search-input"
                className="search-tag"
                placeholder={
                  type === 'REGULAR' || type === 'AUTOMATION'
                    ? 'Add tag'
                    : 'Add collection'
                }
                autoComplete="off"
                autoFocus
                value={value}
                onKeyDown={onClickEnter}
                onChange={(e) => {
                  Setlist([])
                  onPreSearchChanged({
                    text: e.target.value,
                    type: 'TAGLIST',
                    context: isJustCollectionUser()
                      ? 'COLLECTION'
                      : 'WORKSPACE',
                  })
                  setValue(e.target.value)
                  if (e.target.value.length === 0) {
                    resetTagPresearch()
                  }
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <img src={TAG} />
                  </InputAdornment>
                }
              />
            </div>
          )}
          {isAllowed(RULES.ADD_TAG) && (
            <div className="tags-suggestions">
              {(type === 'REGULAR' || type === 'AUTOMATION') &&
                !!suggestions.length && (
                  <h3 className="h3 light">Suggested tags</h3>
                )}
              {type === 'COLLECTION' && !!suggestions.length && (
                <h3 className="h3 light">Your collections</h3>
              )}
              <div className="tags-option">
                {(type === 'REGULAR' || type === 'AUTOMATION') && suggestions}
                {type === 'COLLECTION' && collectionSuggestions}
              </div>
            </div>
          )}
          {tagToAdd && (
            <div className="tags-title">
              <h3 className="h3 light">Create new</h3>
              {(type === 'REGULAR' || type === 'AUTOMATION') &&
                isAllowed(RULES.CREATE_TAG) && (
                  <div
                    className="tag-list-item"
                    onClick={() => {
                      addOrgTag({ tag: tagToAdd, type: TAG_TYPE.REGULAR }).then(
                        (nTag) => {
                          lowerCaseAddTag(fileId, nTag)
                        }
                      )
                    }}
                  >
                    <div className="tag-item">
                      <p className="p">{`Add tag "${tagToAdd}"`}</p>
                    </div>
                  </div>
                )}
              {(type === 'REGULAR' || type === 'AUTOMATION') &&
                !isAllowed(RULES.CREATE_TAG) && (
                  <div className="tag-list-item">
                    <div className="tag-item" style={{ cursor: 'default' }}>
                      <p className="p">{`No permission to create tags`}</p>
                    </div>
                  </div>
                )}
              {type === 'COLLECTION' && isAllowed(RULES.CREATE_COLLECTION) && (
                <div
                  className="tag-list-item"
                  onClick={() => {
                    createCollection({
                      name: tagToAdd,
                      description: '',
                      type: 'regular',
                    }).then((newCollection) => {
                      onaddCollectionToFile({
                        fileId,
                        collectionId: newCollection._id,
                        collection: newCollection,
                      })
                    })
                    clearTextInput()
                  }}
                >
                  <div className="tag-item">
                    <div className="tag-item">
                      <p className="p">{`Add tag "${tagToAdd}"`}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Popover>
  )
}

export default TagList
