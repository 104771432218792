import React, { useState, useRef, useEffect, useMemo } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@mui/material/Divider'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { union, uniqBy } from 'lodash'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'

import { MyKeyword } from '../_MyComponents'

import './bulkedit.scss'
import './mobile.scss'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import TagList from '../TagList'

const useStyles = makeStyles(() => ({
  addTag: {
    textDecoration: 'underline',
    cursor: 'pointer',
    margin: '0',
  },
}))

const BulkEdit = ({
  // from store
  isAllowed,
  role,
  allTags,
  bulkTag,
  bulkAddCollections,
  getAllTags,
  getAllCollections,
  bulkRemoveTags,
  bulkRemoveCollections,

  //from parent
  type = 'REGULAR',
  isOpen,
  closeDialog,
  selectedList,
  setSelectedList,
}) => {
  const classes = useStyles()
  const [selectedTags, setSelectedTags] = useState([])
  const [selectedCollections, setSelectedCollections] = useState([])
  const [anchor, setAnchor] = useState(null)

  // remove tags
  const [existingTags, setExistingTags] = useState([])
  const [tagsToRemove, setTagsToRemove] = useState([])

  // remove collections
  const [existingCollections, setExistingCollections] = useState([])
  const [collectionsToRemove, setCollectionsToRemove] = useState([])

  useEffect(() => {
    if (type === 'REGULAR') {
      getAllTags({})
    } else {
      getAllCollections({})
    }
  }, [])

  // create a list of tags and collections in the selected list
  useMemo(() => {
    let tags = []
    let collections = []
    selectedList.forEach((file) => {
      tags = uniqBy(union(tags, file.tags), '_id')
      collections = uniqBy(union(collections, file.collections), '_id')
    })
    setExistingTags(tags)
    setExistingCollections(collections)
  }, [selectedList])

  const anchorOrigin = {
    vertical: 'top',
    horizontal: 'left',
  }

  const handleClose = () => {
    closeDialog(false)
    setExistingTags([])
    setExistingCollections([])
  }

  const handleCloseTags = () => {
    setAnchor(null)
  }

  const addTagToSelected = ({ tags }) => {
    setSelectedTags(tags)
  }

  const removeTagToSelected = ({ tags }) => {
    setSelectedTags(selectedTags.filter((tag) => tag._id !== tags[0]))
  }

  const addCollectionToSelected = ({ collections }) => {
    setSelectedCollections(collections)
  }

  const removeCollectionToSelected = ({ collection }) => {
    setSelectedCollections(
      selectedCollections.filter((col) => col != collection)
    )
  }

  const onSave = () => {
    let fileIds = selectedList.map((file) => file._id)
    if (type === 'REGULAR') {
      bulkTag({ fileIds: fileIds, tags: selectedTags, clearExisting: false })
      if (tagsToRemove.length > 0) {
        bulkRemoveTags({ fileIds: fileIds, tagIds: tagsToRemove })
      }
    } else {
      const collectionsIds = selectedCollections.map((col) => col._id)
      bulkAddCollections({ fileIds, collectionsIds })
      if (collectionsToRemove.length > 0) {
        bulkRemoveCollections({
          fileIds: fileIds,
          collectionsIds: collectionsToRemove,
        })
      }
    }

    setSelectedList([])
    handleClose()
  }

  const removeTag = (tagId) => {
    setTagsToRemove([...tagsToRemove, tagId])
    const tempTags = existingTags.filter((tag) => tag._id !== tagId)
    setExistingTags(tempTags)
  }

  const removeCollection = (collectionId) => {
    setCollectionsToRemove([...collectionsToRemove, collectionId])
    const tempCollections = existingCollections.filter(
      (col) => col._id !== collectionId
    )
    setExistingCollections(tempCollections)
  }

  const clearExisting = () => {
    setTagsToRemove(existingTags.map((tag) => tag._id))
    setExistingTags([])
    setCollectionsToRemove(existingCollections.map((col) => col._id))
    setExistingCollections([])
  }

  const keywords =
    type === 'REGULAR'
      ? selectedTags.length > 0
        ? selectedTags
            .slice()
            .sort((a, b) => b.count - a.count)
            .map((tag, idx) => {
              const { name } = tag
              return (
                tag.type === type && (
                  <MyKeyword
                    key={`tag-${idx}`}
                    text={name}
                    onDelete={() => removeTagToSelected({ tags: [tag._id] })}
                  />
                )
              )
            })
        : ''
      : selectedCollections.length > 0
      ? selectedCollections
          .slice()
          .sort((a, b) => b.count - a.count)
          .map((col, idx) => {
            const { name } = col
            return (
              <MyKeyword
                key={`col-${idx}`}
                text={name}
                onDelete={() => removeCollectionToSelected({ collection: col })}
              />
            )
          })
      : ''

  const ExistingTags = existingTags.map((tag, idx) => {
    const { name } = tag
    return (
      <MyKeyword
        key={`tag-${idx}`}
        text={name}
        onDelete={() => removeTag(tag._id)}
      />
    )
  })

  const ExistingCollections = existingCollections.map((col, idx) => {
    const { name } = col
    return (
      <MyKeyword
        key={`col-${idx}`}
        text={name}
        onDelete={() => removeCollection(col._id)}
      />
    )
  })

  return (
    <Dialog
      onClose={handleClose}
      fullWidth={true}
      maxWidth={'xs'}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
      className="bulk-edit-dialog"
    >
      <DialogTitle id="form-dialog-title">
        {type === 'REGULAR' ? 'Edit tags for' : 'Edit collections for'}{' '}
        {selectedList.length === 1
          ? `${selectedList.length} file`
          : `${selectedList.length} files`}
      </DialogTitle>
      <DialogContent>
        <h2 className="h2">
          {type === 'REGULAR' ? 'Add Tags' : 'Add to Collections'}
        </h2>
        <div className="bulk-tag-area">
          <span className="tag-placeholder">{keywords}</span>
          <div
            className="add-tag-button"
            onClick={(event) => setAnchor(event.currentTarget.parentNode)}
          >
            {type === 'REGULAR' ? '+ Add tags' : '+ Add to Collection'}
          </div>
        </div>

        {/* remove tags section */}
        {type === 'REGULAR' && !!existingTags.length && (
          <div>
            <Divider className="divider" sx={{ height: 0 }} />
            <div className="remove-tags-wrapper">
              <Accordion elevation={0}>
                <AccordionSummary
                  sx={{ padding: '0', justifyContent: 'start' }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <h2 className="h2">Remove tags</h2>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: '0' }}>
                  <div className="assigned-tags">
                    <p className="h3">Assigned tags</p>
                    <p className="h3 clear-button" onClick={clearExisting}>
                      Clear all
                    </p>
                  </div>
                  <div className="existing-tags">{ExistingTags}</div>
                </AccordionDetails>
              </Accordion>
            </div>
            <Divider className="divider" sx={{ height: 0 }} />
          </div>
        )}

        {/* remove collections section */}
        {type !== 'REGULAR' && !!existingCollections.length && (
          <div>
            <Divider className="divider" sx={{ height: 0 }} />
            <div className="remove-tags-wrapper">
              <Accordion elevation={0}>
                <AccordionSummary
                  sx={{ padding: '0', justifyContent: 'start' }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <h2 className="h2">Remove collections</h2>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: '0' }}>
                  <div className="assigned-tags">
                    <p className="h3">Assigned collections</p>
                    <p className="h3 clear-button" onClick={clearExisting}>
                      Clear all
                    </p>
                  </div>
                  <div className="existing-tags">{ExistingCollections}</div>
                </AccordionDetails>
              </Accordion>
            </div>
            <Divider className="divider" sx={{ height: 0 }} />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={onSave}
          variant="contained"
          color="primary"
          autoFocus
          className={classes.save}
          disabled={
            !selectedTags.length &&
            !selectedCollections.length &&
            !tagsToRemove.length &&
            !collectionsToRemove.length
          }
        >
          Save
        </Button>
      </DialogActions>
      <TagList
        width={{ width: '250px' }}
        type={type}
        isAllowed={isAllowed}
        role={role}
        allTags={allTags}
        anchor={anchor}
        anchorOrigin={anchorOrigin}
        onClose={handleCloseTags}
        tags={selectedTags}
        onAddTag={addTagToSelected}
        onRemoveTag={removeTagToSelected}
        onAddCollection={addCollectionToSelected}
        onRemoveCollection={removeCollectionToSelected}
        collections={selectedCollections}
        /* need to change */
        fileId={'000'}
      />
    </Dialog>
  )
}

export default BulkEdit
